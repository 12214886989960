import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import './App.css';
import logo from './images/noorsurf.png';

function QuranSearch() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/hadith');
    };

    

    const handleClickBookStore = () => {
      navigate('/bookstore');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleQuranSearch = async (page = 1) => {
        if (!searchTerm.trim()) {
            setError('Please enter a search term.');
            setSearchResult(null);
            return;
        }
        try {
            const response = await axios.get('https://api.quran.com/api/v4/search', {
                params: {
                    query: searchTerm,
                    page: page // Pass the current page in the request
                }
            });

            console.log(response.data);  // Log API response for debugging

            if (response.data && response.data.search && response.data.search.results.length > 0) {
                setSearchResult(response.data);
                setCurrentPage(page); // Set the current page
                setError('');
            } else {
                setError('No results found.');
                setSearchResult(null);
            }

        } catch (err) {
            console.error(err);
            setError('An error occurred while fetching data.');
            setSearchResult(null);
        }
    };

    const handlePageChange = (page) => {
        handleQuranSearch(page);
    };

    return (
        <div className="App">
            <header className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">Asalaam!</a>
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    aria-controls="navbarNav" 
                    aria-expanded={menuOpen} 
                    aria-label="Toggle navigation"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">NoorStream</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                Explore
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="/">Action</a>
                                <a className="dropdown-item" href="/">Another action</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">MasjidConnect</a>
                        </li>
                    </ul>

                    <form className="form-inline ml-auto d-flex flex-wrap">
                        <input
                            className="form-control mr-sm-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button
                            className="btn btn-outline-success my-2 my-sm-0"
                            type="button"
                            style={{marginLeft: 10}}
                            onClick={() => handleQuranSearch()}
                        >
                            Search
                        </button>
                    </form>
                </div>
            </header>

            <div className="search-section text-center">
                <img src={logo} alt="NoorSurf Logo" className="logo" style={{ width: '300px', height: 'auto' }} />
                <input type="text" className="form-control search-bar" placeholder="Search..." style={{
                    width: '500px',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    border: '2px solid #ccc',
                    outline: 'none',
                    transition: 'border-color 0.3s ease'
                }}
                    onFocus={(e) => e.target.style.borderColor = 'red'}
                    onBlur={(e) => e.target.style.borderColor = '#ccc'}
                    value={searchTerm}
                    onChange={handleSearchChange} />
                <div className="button-group">
                    <button className="btn btn-light m-2" onClick={() => handleQuranSearch()}>Qur'an</button>
                    <button onClick={handleClick} className="btn btn-light m-2">Hadith</button>
                    <button onClick={() => handleQuranSearch()} className="btn btn-light m-2">Books Store</button>
                </div>
            </div>

            <div className="results-section">
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {searchResult && (
                    <div>
                        {/* <h3>Search Results:</h3>
            <p>Total Pages: {searchResult.search.total_pages}</p>
            <p>Current Page: {currentPage}</p>
            <p>Query: {searchResult.search.query}</p> */}

                        {searchResult.search.results && searchResult.search.results.map((result, index) => (
                            <div key={index} className="card" style={{ margin: '10px', padding: '10px', border: '1px solid #ccc' }}>
                                <h4>Quran: {result.verse_key}</h4>
                                <p>{result.text}</p>
                                <div>
                                    <h5>Translations:</h5>
                                    {result.translations.map((translation, translationIndex) => {
                                        // Replace <em> tags and wrap the searchTerm with <strong> tags
                                        const highlightedText = translation.text
                                            .replace(/<em>/g, '') // Remove opening <em> tags
                                            .replace(/<\/em>/g, '') // Remove closing </em> tags
                                            .replace(new RegExp(searchTerm, 'gi'), (match) => `<strong style="font-weight:bold; color:black">${match}</strong>`);

                                        return (
                                            <p key={translationIndex} dangerouslySetInnerHTML={{ __html: highlightedText }}>
                                                {/* The translation.text with searchTerm highlighted */}
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}


                        <div className="pagination">
                            {/* Show "1" always */}
                            <button
                                className={`btn ${currentPage === 1 ? 'btn-primary' : 'btn-light'}`}
                                onClick={() => handlePageChange(1)}
                                style={{ margin: '5px' }}
                            >
                                1
                            </button>

                            {/* Show ellipsis "..." if currentPage is beyond page 5 */}
                            {currentPage > 5 && (
                                <button className="btn btn-light" style={{ margin: '5px' }} disabled>
                                    ...
                                </button>
                            )}

                            {/* Show 2 pages before the current page if it's beyond the first few pages */}
                            {currentPage > 4 && (
                                <>
                                    <button
                                        className={`btn ${currentPage === currentPage - 2 ? 'btn-primary' : 'btn-light'}`}
                                        onClick={() => handlePageChange(currentPage - 2)}
                                        style={{ margin: '5px' }}
                                    >
                                        {currentPage - 2}
                                    </button>
                                    <button
                                        className={`btn ${currentPage === currentPage - 1 ? 'btn-primary' : 'btn-light'}`}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        style={{ margin: '5px' }}
                                    >
                                        {currentPage - 1}
                                    </button>
                                </>
                            )}

                            {/* Show the current page if it's not the first page */}
                            {currentPage !== 1 && (
                                <button className="btn btn-primary" style={{ margin: '5px' }} disabled>
                                    {currentPage}
                                </button>
                            )}

                            {/* Show 2 pages after the current page */}
                            {currentPage < searchResult.search.total_pages - 1 && (
                                <>
                                    <button
                                        className={`btn ${currentPage === currentPage + 1 ? 'btn-primary' : 'btn-light'}`}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        style={{ margin: '5px' }}
                                    >
                                        {currentPage + 1}
                                    </button>
                                    <button
                                        className={`btn ${currentPage === currentPage + 2 ? 'btn-primary' : 'btn-light'}`}
                                        onClick={() => handlePageChange(currentPage + 2)}
                                        style={{ margin: '5px' }}
                                    >
                                        {currentPage + 2}
                                    </button>
                                </>
                            )}

                            {/* Show ellipsis "..." if currentPage is far from the last page */}
                            {currentPage < searchResult.search.total_pages - 4 && (
                                <button className="btn btn-light" style={{ margin: '5px' }} disabled>
                                    ...
                                </button>
                            )}

                            {/* Show the last page */}
                            <button
                                className={`btn ${currentPage === searchResult.search.total_pages ? 'btn-primary' : 'btn-light'}`}
                                onClick={() => handlePageChange(searchResult.search.total_pages)}
                                style={{ margin: '5px' }}
                            >
                                {searchResult.search.total_pages}
                            </button>
                        </div>


                    </div>
                )}
            </div>
        </div>
    );
}

export default QuranSearch;
