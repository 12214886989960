import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import QuranSearch from './QuranSearch';
import HadithSearch from './HadithSearch';
import BookStoreSearch from './BookStoreSearch';
import Footer from './Footer'; // Importing Footer component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<QuranSearch />} />
          <Route path="/quran" element={<QuranSearch />} />
          <Route path="/hadith" element={<HadithSearch />} />
          <Route path="/bookstore" element={<BookStoreSearch />} />
        </Routes>
        <Footer />  {/* Adding the Footer component at the bottom */}
      </div>
    </Router>
  );
}

export default App;
