import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import logo from './images/noorsurf.png';

function HadithSearch() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };

    const handleClickBookStore = () => {
        navigate('/bookstore');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleHadithSearch = async (page = 1) => {
        if (!searchTerm.trim()) {
            setError('Please enter a search term.');
            setSearchResult(null);
            return;
        }
        try {
            const response = await axios.get('https://www.hadithapi.com/api/hadiths', {
                params: {
                    apiKey: '$2y$10$JFYOTUgzrCQKXKmhuKU3yOLY8noARZO8fexyHOYQAPoOYoQ5tJf9m',
                    hadithEnglish: searchTerm,
                    status: 'Sahih',
                    page: page
                }
            });

            if (response.data && response.data.hadiths && response.data.hadiths.data.length > 0) {
                setSearchResult(response.data);
                setCurrentPage(page);
                setError('');
            } else {
                setError('No results found.');
                setSearchResult(null);
            }

        } catch (err) {
            setError('An error occurred while fetching data.');
            setSearchResult(null);
        }
    };

    const handlePageChange = (page) => {
        handleHadithSearch(page);
    };

    return (
        <div className="App">
            <header className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">Asalaam!</a>
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    aria-controls="navbarNav" 
                    aria-expanded={menuOpen} 
                    aria-label="Toggle navigation"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">NoorStream</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                Explore
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="/">Action</a>
                                <a className="dropdown-item" href="/">Another action</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">MasjidConnect</a>
                        </li>
                    </ul>

                    <form className="form-inline ml-auto d-flex flex-wrap">
                        <input
                            className="form-control mr-sm-2"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button
                            className="btn btn-outline-success my-2 my-sm-0"
                            type="button"
                            style={{marginLeft: 10}}
                            onClick={() => handleHadithSearch()}
                        >
                            Search
                        </button>
                    </form>
                </div>
            </header>

            <div className="search-section text-center">
                <img src={logo} alt="NoorSurf Logo" className="logo" style={{ width: '300px', height: 'auto' }} />
                <input type="text" className="form-control search-bar" placeholder="Search..." style={{
                    width: '500px',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    border: '2px solid #ccc',
                    outline: 'none',
                    transition: 'border-color 0.3s ease'
                }}
                onFocus={(e) => e.target.style.borderColor = 'red'}
                onBlur={(e) => e.target.style.borderColor = '#ccc'}
                 value={searchTerm} onChange={handleSearchChange} />
                <div className="button-group">
                    <button className="btn btn-light m-2" onClick={handleClick} >Qur'an</button>
                    <button onClick={() => handleHadithSearch()} className="btn btn-light m-2">Hadith</button>
                    <button onClick={handleClick} className="btn btn-light m-2">Books Store</button>
                </div>
            </div>

            <div className="results-section">
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {searchResult && (
                    <div>
                        {searchResult.hadiths.data.map((hadith, index) => (
                            <div key={index} className="card">
                                <h4>Hadith Number: {hadith.hadithNumber}</h4>
                                <p><strong>English:</strong> {hadith.hadithEnglish}</p>
                                <p><strong>Urdu:</strong> {hadith.hadithUrdu}</p>
                                <p><strong>Arabic:</strong> {hadith.hadithArabic}</p>
                                <p><strong>Narrator:</strong> {hadith.englishNarrator || hadith.urduNarrator}</p>
                                <p><strong>Status:</strong> {hadith.status}</p>
                                <p><strong>Book:</strong> {hadith.book.bookName} (Volume {hadith.volume})</p>
                                <p><strong>Chapter:</strong> {hadith.chapter.chapterEnglish}</p>
                            </div>
                        ))}
                        <div className="pagination">
                            <button className={`btn ${currentPage === 1 ? 'btn-primary' : 'btn-light'}`} onClick={() => handlePageChange(1)}>1</button>
                            {/* Pagination logic */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HadithSearch;
